@import '../../scss/index.scss';

.view-infarmary {
  .ant-col-xs-24,
  .ant-col-sm-24 {
    @media screen and (max-width: $media-break-point-down-md) {
      padding-top: 2%;
    }
  }

  &-header {
    margin-bottom: 1%;

    .ant-page-header-heading-sub-title {
      font-size: 12px;
      text-transform: capitalize;
    }

    @media screen and (max-width: $media-break-point-down-md) {
      .ant-page-header-heading {
        display: flex;

        .ant-page-header-heading-title {
          white-space: nowrap;
          font-size: xx-small;
        }
        .ant-page-header-heading-sub-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: xx-small;
        }
        .ant-page-header-heading-extra {
          white-space: pre-wrap;
        }
      }
    }
  }

  &__footer {
    margin-top: 2%;
  }
}
