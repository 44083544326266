@import '../../scss/index.scss';

.phones {
  &-header {
    right: 0;
    display: flex;    
    justify-content: space-between;
    margin-bottom: 1%;
     
    h1 {
      text-align: center;
    }

    .ant-btn-primary {
      right: 0;
      margin-left: 2px;
    }



    @media screen and (max-width: $media-break-point-down-md) {
      display: block;
    }
    .ant-input-search  {
      left: 0;
      width: 100%;
      max-height: 40px;
      margin-right: 2%;
    }

    .ant-select {
      width: 70%;
      margin-right: 2%;
      margin-bottom: 1%;

    }
  }
}
