@import '../../scss/index.scss';

.view-info {
  @media screen and (max-width: $media-break-point-down-lg) {
    width: 100%;
  }
  font-size: 1.2em;
  margin: 1%;

  h4 {
    font-weight: bold;
    font-size: 14px;
  }
  h5 {
    font-weight: bold;
    font-size: 16px;
  }
  
  h6 {
    font-weight: normal !important;
    font-size: 13px;
  }
  span {
    font-weight: normal;
    font-size: 13px;
  }

  h3 {
    text-align: center;
  }
  p {
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 0.5%;
    white-space: pre-wrap;
  }

  ol, ul {
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 0.5%;
    white-space: pre-wrap;
  }



  &__signature {
    display: flex;
    flex-direction: column;
    align-items: flex-end; // Alinea todo el bloque a la derecha
    margin-top: 1%;
    margin-right: 2%;
    text-align: center; // Centra los textos internamente en el contenedor
    h4 {
      font-size: 11pt;
    }
    p {
      font-size: 8pt;
    }
  }

  
  &-img {
    width: 18%;
    height: 10%;
  }
  
}

.ant-list-item-action > li {
  vertical-align: middle;
}
