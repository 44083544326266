@import '../../scss/index.scss';

.ant-list-item-action {
  display: flex;
}

.ant-list-item-action-split {
  display: none;
}

.ant-list-item-meta-title {

  font-size: 10pt;
  @media (max-width: $media-break-point-up-lg) {
  word-wrap: keep-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
  @media (max-width: $media-break-point-up-md) {
    font-size: 8pt;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
}

.ant-list-item-action {
  img{
    @media (max-width: $media-break-point-up-lg) {
      display: none;;
    }
  }
}

.ant-dropdown-trigger {
  padding: 0;
  margin: 0;
}
