.edit-user-form {
  .upload-signature {
    display: table;
    margin: 0 auto;
    border: 2px solid #9a9a9a;
    border-style: dashed;
    padding: 10px;
    margin-bottom: 20px;
  }

  .form-edit {
    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .ant-input-prefix {
      color: rgba(0, 0, 0, 0.25);
    }
    .button-submit {
      width: 100%;
    }
  }
}
