@import '../../scss/index.scss';

.anchor {
  .ant-anchor-ink::before {
    background-color: $second-button-color;
    color: #fff;
  }
}

.scroll {
  @media screen and (max-width: $media-break-point-down-lg) {
    overflow-x: scroll;
  }
}
