@import '../../scss/variables.scss';

@import '../../scss/index.scss';

.phones {
  &-header {
    right: 0;
    display: flex;    
    justify-content: space-between;
    margin-bottom: 1%;
     
    h1 {
      text-align: center;
    }

    .ant-btn-primary {
      right: 0;
      margin-left: 2px;
    }



    @media screen and (max-width: $media-break-point-down-lg) {
      display: block;
    }
    .ant-input-search  {
      left: 0;
      width: 100%;
      max-height: 40px;
      margin-right: 2%;
    }

    .ant-select {
      width: 60%;
      margin-right: 2%;
      margin-bottom: 1%;
      @media screen and (max-width: $media-break-point-down-lg) {
        width: 100%;
        margin-right: 0;
      }


    }
  }
}


.card {
    .ant-card-head {
        text-align: center;
        background-color: $primary-button-color;
        color: #fff;
        border-radius: 10px;
    }
}