@import '../../scss/variables.scss';

.ambulatory {
    &__header {

     margin-bottom: 1%;
     display: flex;
     align-items: center;

     .ant-btn-primary {
        right: 0;
        margin-left: 2%;
      }

  
      @media screen and (max-width: $media-break-point-down-sm) {
        display: block;
        .ant-btn-primary {
            right: 0;
            margin-left: 0;
            width: 100%;
          }
      }
      &-switch {
        align-items: center;
        width: auto;

        @media screen and (max-width: $media-break-point-down-sm) {
            font-size: 8pt;
            display: flex;
            margin-bottom: 2%;
          }
        > button {
          margin-right: 5px;
          background-color: $second-button-color;
          border-color: $second-button-color;
          color: #fff;
        }
      }
      &-search {
        margin-left: 2%;
        @media screen and (max-width: $media-break-point-down-sm) {
            margin-left: 0%;
            margin-bottom: 2%;
          }
        flex: 1;
      }
    }
  }
  