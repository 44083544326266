@import '../scss/index.scss';

.layout-admin {
  transition: margin-left 0.2s;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    height: 65px;
  }

  &__content {
    min-height: calc(100vh - 35px);
    padding: 90px 25px 25px 25px;
    background-color: #e4e7e9;
  }

  &__footer {
    text-align: right;
    padding: 15px 5px 0px 0px;
    background-color: #e4e7e9;
    h5 {
      font-size: 8pt;
    }
    @media screen and (max-width: $media-break-point-down-md) {
      h5 {
        font-size: 7pt;
      }   
    }
    @media screen and (max-width: $media-break-point-down-sm) {
      h5 {
        font-size: 6pt;
      }   
    }
  }
}
