@import '../../scss/index.scss';

.evolution {
  .ant-col-xs-24,
  .ant-col-sm-24 {
    @media screen and (max-width: $media-break-point-down-md) {
      padding-top: 2%;
    }
  }

  &-header {
    margin-bottom: 1%;
    .ant-page-header-heading-title {
      text-transform: capitalize;
    }
    .ant-page-header-heading-sub-title {
      font-size: 12px;
      text-transform: capitalize;

    }

    @media screen and (max-width: $media-break-point-down-md) {
      .ant-page-header-heading {
        display: flex;

        .ant-page-header-heading-title {
          font-size: xx-small;      
        }
        .ant-page-header-heading-sub-title {
          font-size: xx-small;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .ant-page-header-heading-extra {
          white-space: pre-wrap;
          display: block;
        }
      }
    }
  }

  &-info {
    &__title {
      text-align: center;
      width: auto;
      @media screen and (max-width: $media-break-point-down-md) {
        width: 60%;
      }
    }
    &__img {
      float: right;
      width: 30%;
      height: 1%;
      margin-right: 1%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
