@import '../../scss/index.scss';

.header {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 5px;
  height: fit-content;
}

.income-view-table {
  height: 780px;
  width: 680px;
  margin-top: 0;
  @media screen and (max-width: $media-break-point-down-lg) {
    width: 100%;
  }

  position: relative;
  margin: 0.5% auto;
  .income-view {
    font-size: 1.2em;
    margin: 1%;
    &-header {
      overflow: hidden;
      &__title {
        float: left;
        width: auto;
        max-width: 65%;
        @media screen and (max-width: $media-break-point-down-md) {
          width: 60%;
        }
      }
      &__img {
        float: right;
        width: 30%;
        height: 1%;
        margin-right: 1%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-personal {
      display: flex;
      justify-content: space-between;
      margin-right: 2%;
    }
    h6 {
      font-size: 13px;
      font-weight: bold;
    }
    span {
      font-size: 13px;
      font-weight: normal;
    }
    text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }
    p, ul {
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 0.5%;
      white-space: pre-wrap;
      max-width: 680px;
    }
  }
}
