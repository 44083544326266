@import '../../../scss/index.scss';

.title-date {

  text-transform: capitalize;
  text-align: center;
  background-color: #1764ab;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 12pt;
  margin: 0;
  button {
    color: #fff;
  }
  @media screen and (max-width: $media-break-point-up-sm) {
    font-size: 10pt;
  }
}

.turns-list {
  background-color: #fff;
  padding: 10px 20px;

  .ant-list-items {
    @media screen and (min-width: $media-break-point-up-sm) {
      align-items: initial;
    }
    .ant-list-item {
      flex-wrap: wrap-reverse;
    }
  }



  &__date-cel {
    @media screen and (min-width: $media-break-point-up-sm) {
      display: none;
    }
    color: $font-light;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: $second-button-color;
    margin-right: 2%;
    @media screen and (max-width: $media-break-point-down-sm) {
      margin-right: 0px;
      display: inline-block;
      text-align: center;
      margin-top: 5px;
    }
    border-radius: 5px;
    text-transform: capitalize;
    padding: 5px 15px;
  }

  &__date {
    color: $font-light;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: $second-button-color;
    margin-right: 2%;
    @media screen and (max-width: $media-break-point-down-sm) {
      margin-right: 0px;
      display: inline-block;
      text-align: center;
      margin-top: 5px;
    }
    border-radius: 5px;
    text-transform: capitalize;
    padding: 5px 15px;
  }

  .ant-list-item-meta-title {
    font-size: 12pt;
    @media screen and (max-width: $media-break-point-down-sm) {
      font-size: 10pt;
    }
    span {
      font-size: 9pt;
      color: gray;
      @media screen and (max-width: $media-break-point-down-sm) {
        font-size: 8pt;
      }
    }

  }


  .ant-tag > .anticon + span, .ant-tag > span + .anticon {
    @media screen and (max-width: $media-break-point-down-sm) {
      display: none;
    }
  }

  .ant-list-item-meta {
    display: flex;
    align-items: center;
  }

  .ant-list-item-action {
    li:nth-child(0) {
      @media screen and (max-width: $media-break-point-down-sm) {
        display: none;
      }
    }
  }
  .ant-list-item-action {
    display: flex;
    margin-right: 5px;
  }

  .ant-list-item-action-split {
    display: none;
  }

  .ant-list-item-meta-title {
    text-transform: capitalize;
  }
}

