@import '../../../scss/index.scss';

.admin-sider-infarmary {
  background-color: #3e4b5e;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 65px;

  .ant-menu {
    background: #3e4b5e;
  }
}
