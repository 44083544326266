@import '../../scss/index.scss';

.configuration-list {
  background-color: #fff;
  padding: 10px 20px;

  .ant-btn-primary .ant-list-item-meta-title {
    font-size: 16px;
  }
  .ant-list-item-meta-title {
    text-transform: capitalize;
  }

  .ant-list-item-meta {
    display: flex;
    align-items: center;
  }

  .ant-list-item-action {
    margin: 5px;
  }

  .ant-list-item-action-split {
    display: none;
  }

  &-switch {
    align-items: center;
    width: auto;

    @media (max-width: $media-break-point-up-sm) {
      width: 20%;
    }
    > button {
      margin-right: 20px;
      background-color: $second-button-color;
      border-color: $second-button-color;
      color: #fff;
    }
  }
}
