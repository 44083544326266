@import '../../scss/index.scss';

.page {
  display: flex;
  overflow: hidden;
  background-color: $primary-button-color;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media (max-width: $media-break-point-up-sm) {
    flex-direction: column;
  }

  &-button {
    @media (max-width: $media-break-point-up-sm) {
      display: none;
    }
    align-content: center;
    margin-top: 10%;
    button {
      background-color: $primary-button-color;
      color: #fff;
    }
  }
  &-right {
    text-align: center;
    align-self: center;
    height: auto;
    margin: 5%;
    width: 50%;
    h1 {
      color: #fff;
      font-size: 40px;
      margin: 30px;
      font-weight: bold;
    }
    h2 {
      color: #fff;
      font-size: x-large;
      margin: 30px;
    }
    h3 {
      color: #fff;
      font-size: large;
      margin: 30px;
    }

    @media (max-width: $media-break-point-up-lg) {
      width: 80%;
      margin: 0;
      h1 {
        color: #fff;
        font-size: 25px;
      }
      h2 {
        color: #fff;
        font-size: 20px;
      }
      h3 {
        color: #fff;
        font-size: 15px;
      }
      img {
        width: 100%;
        margin: 5%;
      }
      @media (max-width: $media-break-point-up-md) {
        img {
          width: 100%;
        }
      }
    }
  }
  &-left {
    width: 50%;
    margin: 5%;
    align-self: center;
    height: auto;

    img {
      width: 100%;
    }

    @media (max-width: $media-break-point-up-md) {
      width: 100%;
      height: 50vh;
      margin-top: 10%;

      img {
        margin-top: 5;
        width: 100%;
        height: 80%;
      }
    }
  }
}