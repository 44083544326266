.list {
    .ant-list-header {
        background-color: #fff;
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;
    }    

}

.card {
    border-radius: 5px;
    .ant-card-meta {
        text-align: center;
        .ant-card-meta-title, .ant-card-meta-description {
        white-space: pre-wrap;
        }
    }
}

.head-card {
    background-color: #E9E9E9;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
}