@use "sass:math";

@import '../../scss/index.scss';

.register-form {
  .ant-form-item:last-of-type {
    margin: 0;
  }
  &__input {
    .ant-input-prefix {
      font-size: 0em + math.div(22px, $default-font-size);
    }

    input {
      font-size: 0em + math.div(20px, $default-font-size);
      padding: 5px 5px 5px 5px !important;
    }

    &:focus {
      border-color: $primary-color;
      box-shadow: none;
    }
  }

  &__button {
    width: 100%;
    font-size: 0em + math.div(22px, $default-font-size);
    height: 40px;
    margin-top: 22px;
  }
}
