@import '../../scss/variables.scss';
.calendar {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;

    &__rigth {
      display: flex;
      .ant-btn-primary {
        right: 0;
      }
      @media screen and (max-width: $media-break-point-down-md) {
        display: block;
      }
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: $primary-button-color !important;
    border-color: $primary-button-color !important;
    &:hover {
      background-color: $primary-button-color-hover !important;
      border-color: $primary-button-color-hover !important;
      color: $font-light !important;
    }

    &:active,
    &:focus {
      background-color: $primary-button-color !important;
      border-color: $primary-button-color !important;
      color: $font-light !important;
      border: 0 !important;
    }
  }


  [data-theme='dark'] .site-calendar-demo-card {
    border: 1px solid #303030;
  }
}

.site-calendar-demo-card {
  .ant-select {
    margin-right: 2%;
  }


.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    z-index: 0;
}

  
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
  }
 
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date 
  .ant-picker-calendar-date-content {
    font-size: 8pt;

    p {
      display: none;
    }


    @media screen and (max-width: $media-break-point-down-md) {
      ul {
        display: none
      };
      p {
        display: flex;
      }
      button {
        display: flex;
        font-size: 8pt;
        padding: 3px;
        text-align: center;
      }
 
    }
  }


}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}


