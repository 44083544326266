@import '../../scss/index.scss';
@import '../../scss/variables.scss';

.ocupations {    

  .ant-card {
    margin-bottom: 5px;
    border-radius: 10px;

  }
  .ant-card-head {
    border-radius: 10px;
    text-align: center;
    height: 10px;
    background-color: $primary-button-color;
    color: #fff;
    font-size: 9pt;
    @media (max-width: $media-break-point-up-lg) {
      font-size: 8pt;
    } 
    @media (max-width: $media-break-point-up-sm) {
      font-size: 7pt;
    } 
  }

  .tooltip-visible{
    @media (max-width: $media-break-point-up-sm) {
    display: none;
  }

  }

  .ant-card-body {
    @media (min-width: $media-break-point-up-lg) {
      padding: 16px;
    }
    border-radius: 10px;

    padding: 10px;
    max-height: 100px;
  }

  .ant-card-actions {
    border-radius: 10px;
  }

  .ant-statistic-content,
  .ant-statistic-content-suffix {
    text-align: center;
    @media (max-width: $media-break-point-up-xs) {
      font-size: 8pt;
    }
    @media (max-width: $media-break-point-up-md) {
      font-size: 9pt;
    }
    @media (min-width: $media-break-point-up-md) {
      font-size: 10pt;
    }
    @media (min-width: $media-break-point-up-lg) {
      font-size: 12pt;
    }
    @media (min-width: $media-break-point-up-xl) {
      font-size: 16pt;
    }
  }

  .ant-progress-inner {
    background-color: rgb(0 0 0 / 12%);
  }
}
