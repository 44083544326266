@import '../../../scss/index.scss';
@import '../../../scss/variables.scss';

.card-patient {
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 5px;
  .ant-collapse {
    margin-bottom: 5px;
    border-radius: 5px;
  }


  
  .ant-collapse-item .ant-collapse-header  {
    text-align: center;
    text-transform: capitalize;
    background-color: $primary-button-color-hover;
    color: #fff;
    @media (max-width: $media-break-point-up-lg) {
      font-size: 12pt;
    }
    @media (max-width: $media-break-point-up-md) {
      font-size: 10pt;
    }
    @media (max-width: $media-break-point-up-sm) {
      text-align: left;
      font-size: 8pt;
    }
  }


  .ant-collapse-extra {
    .ant-btn-link {
      @media (max-width: $media-break-point-up-lg) {
        font-size: 10pt;
      }
      @media (max-width: $media-break-point-up-md) {
        font-size: 10pt;
        width: 16px;
      }

    }
  }

  .patient-actions {
    text-align: center;
    @media (max-width: $media-break-point-up-sm) {
      font-size: 8pt;
    }
    &_title {
      @media (max-width: $media-break-point-up-sm) {
        font-size: 6pt;
      }
    }
  }

  .ant-btn-default {
    margin-left: 5px;
  }
}
