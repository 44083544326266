@import '../../scss/index.scss';

.operation {
  &-header {
    margin-bottom: 1%;
    .ant-page-header-heading-title {
      text-transform: capitalize;
    }
    
    .ant-page-header-heading-sub-title {
      font-size: 12px;
      text-transform: capitalize;
    }

    @media screen and (max-width: $media-break-point-down-md) {
      .ant-page-header-heading {
        display: flex;
        text-transform: capitalize;

        .ant-page-header-heading-title {
          font-size: xx-small;      
        }
        .ant-page-header-heading-sub-title {
          font-size: xx-small;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .ant-page-header-heading-extra {
          white-space: pre-wrap;
          display: block;
        }
      }
    }
  }
  @media (max-width: $media-break-point-up-lg) {
    display: block;
  }
}
