@import '../../../scss/variables.scss';

.ant-descriptions-item-label {
  font-weight: 600;
}

.ant-descriptions-item-label,
.ant-descriptions-item-content {
  @media (max-width: $media-break-point-up-lg) {
    font-size: 12pt;
  }
  @media (max-width: $media-break-point-up-md) {
    font-size: 10pt;
  }

  @media (max-width: $media-break-point-up-sm) {
    font-size: 7pt;
  }
}


.patient-actions {
  text-align: center;
  @media (max-width: $media-break-point-up-sm) {
    font-size: 8pt;
  }
  &_title {
    @media (max-width: $media-break-point-up-sm) {
      font-size: 6pt;
    }
  }
}
