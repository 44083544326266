@import '../../scss/index.scss';

.message-color {
  background-color: #002a52;
  width: 100%;

  .ant-card-head-title {
    text-align: center;
    background-color: #002a52;
    color: #fff;
  }
  .ant-card-body-style {
    background-color: #17558f;
    color: #fff;
  }
}

.home {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 70vh;

  @media (max-width: $media-break-point-up-lg) {
    display: block;
  }

  &-statistic {
    text-align: center;
    @media (max-width: $media-break-point-up-lg) {
      font-size: 60%;
    }
  }
  h3 {
    text-align: center;
  }
  &-logo {
    width: 100%;
    display: flex;
    padding: 5%;
    max-height: 50vh;
    text-align: center;

    img {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 3/2;
      }    
    
    &-rigth {
      text-align: center;
      width: 50%;
      img {
        padding: 8px;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 3/2;
      }
    }
    &-left {
      width: 50%;
      text-align: center;
      img {
       padding: 8px;
       width: 100%;
       object-fit: contain;
       aspect-ratio: 3/2;
      }
    }
  }
}


.sm-responsive {
  @media (min-width: $media-break-point-up-md) {
    display: none;
  }
  h6 {
    text-align: center;
    font-size: 11pt;

    @media (max-width: 350px) {
      font-size: 8pt;
    }
  }
  .messages {
    position: absolute;
    padding-right: 15px;
    @media (max-width: $media-break-point-up-sm) {
      padding-right: 5px;
    }
    right: 0;
    top: 85px;
  }
}

.md-responsive {
  @media (max-width: $media-break-point-up-md) {
    display: none;
  }
}
