@import '../../scss/index.scss';

.income {
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
    .ant-btn-primary {
      right: 0;
      margin-left: 5px;
    }


    .ant-statistic-content {
      font-size: large;
      .ant-statistic-content-suffix {
        font-size: large;
      }
    }

    @media screen and (max-width: $media-break-point-down-sm) {
      display: block;
      .ant-statistic-content {
        font-size: medium;
        .ant-statistic-content-suffix {
          font-size: medium;
        }
      }
    }


      &_search {
        margin-left: 2%;
        margin: 0 1% 0 1%;
        max-height: 40px;

        @media screen and (max-width: $media-break-point-down-sm) {
            margin-left: 0%;
            margin-bottom: 2%;
          }
        flex: 1;
        }
  }
}
