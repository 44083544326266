@import '../../scss/index.scss';

.income-history {
  &-header {
    right: 0;
    display: flex;
    margin-bottom: 1%;
    max-height: 40px;

    .ant-input-search {
      .ant-input-suffix {
        @media screen and (max-width: $media-break-point-down-sm) {
          width: 60%;
        }
      }

      left: 0;
      width: 100%;
    }

    .ant-badge-count {
      background: $second-button-color;
    }      
    &_filter{
     
      .ant-row {
       padding: 10px;
      }
    } 

   
  }
}
