@import '../../../scss/index.scss';

.menu-top-infarmary {
  display: flex;
  background-color: #3e4b5e;

  button {
    color: $font-light;

    &:hover {
      color: $primary-color;
    }

    &:focus,
    &:active {
      color: $font-light;
    }
  }

  &-left {
    .ant-btn {
      margin-left: 5px;
    }
    &__logo {
      width: 200px;
      height: 40px;
      padding: 0 10px;
    }
  }

  &-left-mini {
    .ant-btn {
      margin-left: 5px;
    }
    &__logo {
      height: 55px;
      padding: 0 10px;
    }
  }

  .ant-dropdown-trigger {
    color: #fff;

    @media screen and (max-width: $media-break-point-down-lg) {
      font-size: 12px;
    }
    @media screen and (max-width: 500px) {
      span {
        display: none;
      }
    }
    font-size: 16px;
  }
}
