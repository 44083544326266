@import '../../../scss/index.scss';

.income-infarmary {
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
    .ant-btn-primary {
      right: 0;
      margin-left: 2px;
    }
    @media screen and (max-width: $media-break-point-down-md) {
      display: block;
      .ant-statistic-content {
        font-size: small;
        .ant-statistic-content-suffix {
          font-size: x-large;
        }
      }
    }

    @media screen and (max-width: $media-break-point-down-xl) {
      .ant-statistic-content {
        font-size: small;
        .ant-statistic-content-suffix {
          font-size: x-large;
        }
      }
    }

    .ant-input-search {
      width: 70%;
      max-height: 40px;
      margin: 0 1% 0 0;
    }
  }
}
