@import '../../scss/index.scss';

.table {
  .ant-table-thead {
    .ant-table-cell {
      border-bottom: 1px solid rgb(0 0 0 / 8%);
    }
  }
}

.table .ant-table-thead .ant-table-cell {
  text-transform: none;
}
.text-uppercase {
  text-transform: uppercase;
}

.ant-dropdown-trigger {
  padding: 4px 15px !important;
  
}