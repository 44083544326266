@import '../../scss/index.scss';

.income-pass-view-table {
  height: 780px;
  width: 680px;
  @media screen and (max-width: $media-break-point-down-lg) {
    width: 100%;
  }

  .income-view-pass-date {
    width: 20%;
    margin-left: 1%;
    margin-top: 1%;
    h4 {
      font-weight: bold;
    }
  }

  .income-view-img-pass {
    float: right;
    width: 25%;
    height: 20%;
    margin-top: 0;
    img {
      width: 100%;
      height: 100%;
      @media screen and (max-width: $media-break-point-down-md) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .k-pdf-export {
    font-family: Arial, Helvetica, sans-serif;
    h4,
    h6 {
      font-weight: bold;
    }
    text {
      color: black;
      font-weight: 'bold';
      font-size: 12px;
    }
    span {
      color: rgb(110, 110, 110);
      margin-right: 2;
    }
    p {
      color: rgb(110, 110, 110);
      margin-right: 2;
      font-weight: normal;
      white-space: pre-wrap;
    }
  }
  position: relative;
  margin: 0.5% auto;
  .income-pass-view {
    font-size: 1.2em;
    margin: 1%;
    &-header-pass {
      overflow: hidden;
      &__title {
        float: left;
        width: auto;

        &-pass {
          width: calc(100% + 1%);
          background-color: rgb(180, 177, 177);
          margin-top: 0;
        }
      }
      &__img {
        float: right;
        width: 30%;
        height: 1%;
        margin-right: 1%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-personal {
      display: flex;
      justify-content: space-between;
      margin-right: 2%;
    }

    h6 {
      font-weight: bold;
    }
    span {
      font-weight: normal;
    }
    text {
      font-weight: bold;
      color: black;
    }
    p {
      font-weight: normal;
      font-size: 11.256px;
      margin-bottom: 0.5%;
      white-space: pre-wrap;
    }
  }
}
