@import '../../scss/index.scss';
@import '../../scss/variables.scss';

.cards {


  &-switch {
    align-items: center;
    width: auto;

    @media (max-width: $media-break-point-up-sm) {
      width: 20%;
    }   

    span {
      margin-right: 10px;
    }
  }

  .ant-card {
    margin-bottom: 5px;

  }



  .ant-card-cover {
   
    img {
      height: 20vh;
      object-fit: cover;    
      object-position: 50% 10%;
    }
  }

 
  

  .tooltip-visible{
    @media (max-width: $media-break-point-up-sm) {
    display: none;
  }

  }

  .ant-card-body {
    @media (min-width: $media-break-point-up-lg) {
      padding: 16px;
    }

    padding: 10px;
    height: 150px;
  }



  .ant-statistic-content,
  .ant-statistic-content-suffix {
    text-align: center;
    @media (max-width: $media-break-point-up-xs) {
      font-size: 8pt;
    }
    @media (max-width: $media-break-point-up-md) {
      font-size: 9pt;
    }
    @media (min-width: $media-break-point-up-md) {
      font-size: 10pt;
    }
    @media (min-width: $media-break-point-up-lg) {
      font-size: 12pt;
    }
    @media (min-width: $media-break-point-up-xl) {
      font-size: 16pt;
    }
  }

  .ant-progress-inner {
    background-color: rgb(0 0 0 / 12%);
  }
}

.prices {
  text-align: right;
  font-size: 10pt;
  font-weight: 500;
  padding-bottom: 5px;
  &-total {
    font-size: 12pt;
    font-weight: 900;
  }

}

.button-submit {
    margin-top: 10px;
    width: 100%;
}

