@import '../../../scss/variables.scss';
.control-table {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;

  table,
  th,
  td {
    font-size: 10pt;
    text-align: center;
    color: black;
    border: 1px solid black;
    padding-bottom: 5px;
  }
  @media screen and (max-width: $media-break-point-down-md) {
    table,
    th,
    td {
      font-size: 8pt;
      text-align: center;
      color: black;
      border: 1px solid black;
      padding-bottom: 5px;
    }
  }
  td:nth-last-child(1) {
    text-align: left;
  }
  th {
    @media screen and (max-width: $media-break-point-down-sm) {
      word-break: break-all;
      font-size: 6pt;
    }
    background-color: #002a52;
    color: #eef0f4;
  }
}
.title {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-weight: bold;
}
