@import '../../scss/index.scss';

.description {
  @media (max-width: $media-break-point-down-xs) {
    display: none;
  }
  span {
    text-transform: none;
  }
}

.ant-drawer-title,
.ant-drawer-close {
  color: #fff;
  font-weight: 500;
}

.ant-drawer {
  .ant-btn-link {
    right: 0;
    position: absolute;
    top: 10px;
    color: #fff;
    margin-right: 30px;
  }
  p {
    color: black;
  }
}

p {
  font-weight: normal;
  font-size: 13px;
  margin-bottom: 0.5%;
  white-space: pre-wrap;
}
